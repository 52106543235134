/* eslint-disable no-extra-boolean-cast */
import authService from "auth/auth";
import Cookies from "js-cookies";
import jwt_decode from "jwt-decode";
import CONFIG from "constants/config";
import * as utils from "@kdshared/okta-utils";

const { RELOAD_ATTEMPTS } = CONFIG.cookies;

export async function isUserAuthenticated() {
  try {
    const accessToken = await authService.getAccessToken();
    return !!accessToken;
  } catch {
    return false;
  }
}

export function getUserEmail() {
  try {
    const userEmail = utils.getUserClaims()?.email;
    if (!userEmail) {
      throw new Error("users email does not exist");
    }
    return userEmail;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("OKTA getUserEmail", err);
    return null;
  }
}

export async function getUserHrId() {
  const accessToken = await authService.getAccessToken();
  if (accessToken) {
    const decodedToken = jwt_decode(accessToken);
    const { HRID: hrId } = decodedToken;
    return hrId;
  }
  return null;
}

export function getPsId() {
  return utils.getPsId();
}

export async function getUserAccessToken() {
  const accessToken = await authService.getAccessToken();
  return !!accessToken;
}

export function clearAuthCache() {
  // eslint-disable-next-line no-console
  console.error("OKTA auth 001 - clearing cache");
  utils.clearOktaCookies();
}
export async function isSessionExpired() {
  const accessToken = await authService.getAccessToken();
  if (accessToken) {
    const decodedToken = jwt_decode(accessToken);
    const { exp } = decodedToken;
    return Date.now() >= exp * 1000;
  }
  return true;
}

export function getNumberOfRelaodAttempts() {
  return Cookies.getItem(RELOAD_ATTEMPTS);
}

export function removeNumberOfRelaodAttempts() {
  Cookies.removeItem(RELOAD_ATTEMPTS);
}

export function setNumberOfRelaodAttempts() {
  const reloadAttempts = getNumberOfRelaodAttempts();
  if (reloadAttempts) {
    Cookies.setItem(RELOAD_ATTEMPTS, Number(reloadAttempts) + 1);
  } else {
    Cookies.setItem(RELOAD_ATTEMPTS, 1);
  }
}

export function reloadApplicationOnAuthError() {
  clearAuthCache();
  // eslint-disable-next-line no-console
  console.error("OKTA auth 002 - reloading page");
  if (window.location.href.indexOf("/error") > -1) {
    window.location.href = "/";
  } else {
    window.location.reload();
  }
}

export const getParsedToken = (cookieName) => {
  const rawToken = Cookies.getItem(cookieName);
  return rawToken ? JSON.parse(rawToken) : null;
};
