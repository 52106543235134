import { call, put as putSaga } from "redux-saga/effects";
import { put } from "redux/api";
import { setUpdateTopicSuccess, setUpdateTopicError } from "redux/actions/topics";

function* handleUpdateTopic({ payload }) {
  const { res, error } = yield call(put, payload);
  if (error) {
    yield putSaga(setUpdateTopicError(error));
  } else {
    yield putSaga(setUpdateTopicSuccess(res));
  }
}

export default handleUpdateTopic;
