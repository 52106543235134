import { skills } from "redux/constants";

const skillUpdatingSuccessReducer = (state = null, action) => {
  switch (action.type) {
    case skills.UPDATE_SKILL_SUCCESS:
      return action?.data || null;
    case skills.UPDATE_SKILL_FAILURE:
      return null;
    case skills.UPDATE_SKILL:
      return false;
    default:
      return state;
  }
};

export default skillUpdatingSuccessReducer;
