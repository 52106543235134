import { topics } from "redux/constants";

const topicUpdatingSuccessReducer = (state = null, action) => {
  switch (action.type) {
    case topics.UPDATE_TOPIC_SUCCESS:
      return action?.data || null;
    case topics.UPDATE_TOPIC_FAILURE:
      return null;
    case topics.UPDATE_TOPIC:
      return false;
    default:
      return state;
  }
};

export default topicUpdatingSuccessReducer;
